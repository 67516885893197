.selectedRequestContainer {
    margin-top: 10px;
}

.previewButtonGroup {
    display: flex;
    justify-content: flex-end;
}

.errorMsg {
    color: red;
    margin-top: 10px;
    margin-bottom: 0;
}

.successMsg {
    color: green;
    margin-top: 10px;
    margin-bottom: 0;
}

.completeIcon {
    margin-left: 10px;
}

.trialUserTypeSelect {
    width: 100%;
}