.createModal {
    width: 90% !important;
    max-width: 90% !important;
}

.savingSpinner {
    margin-right: 10px;
}

.addressLabel {
    margin-bottom: 1rem;
}

.createSearch {
    margin-bottom: 10px;
}

.createTypeGroup {
    margin-bottom: 15px !important;
}