.emailTemplateModal {
    width: 90% !important;
    max-width: 90% !important;
}

.emailContent {
    font: small/1.5 Arial, Helvetica, sans-serif;
}

.emailContent p {
    margin-bottom: 0;
}