.searchBtn {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 20px !important;
}

.reviewEmailBtn {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 80px;
    border-radius: 20px !important;
}

.reviewFlagBtn {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 80px;
    border-radius: 20px !important;
}

.reviewImpAddInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 130px;
    border-radius: 20px !important;
}


.reviewAddInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 180px;
    border-radius: 20px !important;
}

.reviewGroupInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 230px;
    border-radius: 20px !important;
}

.metaSpecialtiesInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 280px;
    border-radius: 20px !important;
}

.rescrapeInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 330px;
    border-radius: 20px !important;
}

.hiddenInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 380px;
    border-radius: 20px !important;
}

.editConfig {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 430px;
    border-radius: 20px !important;
}

.createInfo {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 480px;
    border-radius: 20px !important;
}