.groupModal {
    width: 90% !important;
    max-width: 90% !important;
}

.completeIcon {
    margin-right: 10px;
}

.doctorSpecialtyBadge {
    margin-left: 10px;
}

.selectedDoctorListGroup {
    height: 700px;
    overflow-y: scroll;
}

.selectedSearchDoctorListGroup {
    margin-top: 15px;
}

.groupFilterSelect {
    width: 280px;
    margin-left: 30px;
}