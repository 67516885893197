.homeLoadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.myMain {
    padding: 25px 25px;
}

.previewButtonGroup {
    display: flex;
    justify-content: flex-end;
}

.errorMsg {
    color: red;
    margin-top: 10px;
    margin-bottom: 0;
}

.successMsg {
    color: green;
    margin-top: 10px;
    margin-bottom: 0;
}

.versionBadge {
    z-index: 20;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 30px;
    margin-bottom: 30px;
}