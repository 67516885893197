.loadingSpinner {
    margin-right: 10px;
}

.btn-blue {
    background-color: #166088 !important;
    color: white !important;
}

.btn-blue:hover {
    border-color: #166088 !important;
    /* color: #3056D3 !important; */
}

.btn-blue:focus {
    box-shadow: 0 0 0 0.25rem rgb(48 86 211 / 25%) !important;
}

.performActionBtn {
    margin-left: 10px;
}

.actionSelection {
    width: 240px;
}

.rescheduleDatePicker {
    margin-right: 15px;
}

.react-datepicker-wrapper {
    width: unset;
}

.emailTemplateBtn {
    margin-right: 10px;
}