.editConfigModal {
    width: 90% !important;
    max-width: 90% !important;
}

.configList {
    height: 400px;
    overflow-y: scroll;
}

.formLabel {
    font-weight: bold;
}

.metaSelection {
    width: 100%;
}

.loadingSpinner {
    margin-right: 10px;
}